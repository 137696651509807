import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../axios';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


function DefaultRestriction() {
    const { chatId } = useParams();
    const [responseData, setResponseData] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        const getDefaultRestrictions = async () => {
            try {
                const response = await axiosInstance.get(`/chatSettings/chatDefaultRestrictions/${chatId}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setResponseData(response.data);
            } catch (error) {
                console.log(error);
                toast.error(t("Failed to fetch default restrictions settings.")+ JSON.stringify(error.response.data));
            }
        };

        getDefaultRestrictions();
    }, [chatId, t]);

    const handleInputChange = (e, field) => {
        setResponseData({
            ...responseData,
            [field]: parseInt(e.target.value, 10),
        });
    };

    const handleUpdate = async () => {
        try {
            const response = await axiosInstance.post(`/chatSettings/chatDefaultRestrictions/`, {...responseData}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                toast.success(t("Default restrictions settings updated successfully."));
            } else {
                toast.error(response.data);
            }
        } catch (error) {
            toast.error(t("Failed to update default restrictions settings.")+ JSON.stringify(error.response.data));
        }
    };

    return (
        <div className="container mt-4">
            <h1 className="mb-4">{t("Default restrictions Settings")}</h1>
            <div className="card mb-3">
                <div className="card-body">
                    <div className="mb-3">
                        <label htmlFor="default_mute_time_min" className="form-label">{t("Default mute")}</label>
                        <div className="input-group">
                            <input
                                type="number"
                                className="form-control"
                                id="default_mute_time_min"
                                value={responseData.default_mute_time_min || ''}
                                onChange={(e) => handleInputChange(e, 'default_mute_time_min')}
                            />
                            <span className="input-group-text">{t("min.")}</span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="default_ban_time_min" className="form-label">{t("Default ban")}</label>
                        <div className="input-group">
                            <input
                                type="number"
                                className="form-control"
                                id="default_ban_time_min"
                                value={responseData.default_ban_time_min || ''}
                                onChange={(e) => handleInputChange(e, 'default_ban_time_min')}
                            />
                            <span className="input-group-text">{t("min.")}</span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="default_warn_time_min" className="form-label">{t("Default warn")}</label>
                        <div className="input-group">
                            <input
                                type="number"
                                className="form-control"
                                id="default_warn_time_min"
                                value={responseData.default_warn_time_min || ''}
                                onChange={(e) => handleInputChange(e, 'default_warn_time_min')}
                            />
                            <span className="input-group-text">{t("min.")}</span>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="warn_max_count" className="form-label">{t("Warn max count")}</label>
                        <input
                            type="number"
                            className="form-control"
                            id="warn_max_count"
                            value={responseData.warn_max_count || ''}
                            onChange={(e) => handleInputChange(e, 'warn_max_count')}
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="warn_restriction_id" className="form-label">{t("Restriction")}</label>
                        <select
                            className="form-select"
                            id="warn_restriction_id"
                            value={responseData.warn_restriction_id || ''}
                            onChange={(e) => handleInputChange(e, 'warn_restriction_id')}
                        >
                            <option value={2}>{t("Mute")}</option>
                            <option value={3}>{t("Ban")}</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="warn_restriction_time_min" className="form-label">{t("Restriction time")}</label>
                        <div className="input-group">
                            <input
                                type="number"
                                className="form-control"
                                id="warn_restriction_time_min"
                                value={responseData.warn_restriction_time_min || ''}
                                onChange={(e) => handleInputChange(e, 'warn_restriction_time_min')}
                            />
                            <span className="input-group-text">{t("min.")}</span>
                        </div>
                    </div>
                    <button className="btn btn-primary w-100" onClick={handleUpdate}>
                        {t("Update")}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default DefaultRestriction;
