import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import axiosInstance from "../../../axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


function MessageLinks() {
    const { chatId } = useParams();
    const [responseData, setResponseData] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        const getFilterUrlsSettings = async () => {
            try {
                const response = await axiosInstance.get(`/chatSettings/chatFilterUrls/${chatId}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setResponseData(response.data);
            } catch (error) {
                console.log(error);
                toast.error(t("Failed to fetch message links settings.")+ JSON.stringify(error.response.data));
            }
        };

        getFilterUrlsSettings();
    }, [chatId, t]);

    const handleInputChange = (e, field) => {
        setResponseData({
            ...responseData,
            [field]: e.target.value,
        });
    };

    const handleCheckboxChange = (e, field) => {
        setResponseData({
            ...responseData,
            [field]: e.target.checked,
        });
    };

    const handleUpdate = () => {
        const updateFilterUrls = async () => {
            try {
                const response = await axiosInstance.post(`/chatSettings/chatFilterUrls/`, {...responseData}, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.status === 200) {
                    toast.success(t("Message links settings updated successfully."));
                } else {
                    toast.error(response.data);
                }
            } catch (error) {
                console.log(error);
                toast.error(t("Failed to update message links settings.")+ JSON.stringify(error.response.data));
            }
        };
        updateFilterUrls();
    };

    return (
        <div className="container mt-4">
            <h1 className="mb-4">{t("Message Links Filter")}</h1>
            <div className="card mb-3">
                <div className="card-body">
                    <div className="form-check form-switch mb-3">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="filterUrlToggle"
                            checked={responseData.filter_url_on || false}
                            onChange={(e) => handleCheckboxChange(e, 'filter_url_on')}
                        />
                        <label className="form-check-label" htmlFor="filterUrlToggle">
                            {t("Filter URL")}
                        </label>
                    </div>
                    <div className="form-check form-switch mb-3">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="filterAllUrlsToggle"
                            checked={responseData.filter_all_urls || false}
                            onChange={(e) => handleCheckboxChange(e, 'filter_all_urls')}
                        />
                        <label className="form-check-label" htmlFor="filterAllUrlsToggle">
                            {t("Filter all links")}
                        </label>
                    </div>
                    <div>
                        <span>{t("Use ; between links")}</span>
                        <textarea
                            className="form-control mt-2"
                            rows="3"
                            value={responseData.filter_urls || ''}
                            onChange={(e) => handleInputChange(e, 'filter_urls')}
                        />
                    </div>
                </div>
            </div>
            <button className="btn btn-primary w-100" onClick={handleUpdate}>
                {t("Update")}
            </button>
        </div>
    );
}

export default MessageLinks;
