import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../axios';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


function DefaultRestriction() {
    const { chatId } = useParams();
    const [responseData, setResponseData] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        const getRules = async () => {
            try {
                const response = await axiosInstance.get(`/chatSettings/chatRules/${chatId}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setResponseData(response.data);
            } catch (error) {

                console.log(error);
                toast.error(t("Failed to fetch rules.")+ JSON.stringify(error.response.data));
            }
        };

        getRules();
    }, [chatId, t]);

    const handleInputChange = (e, field) => {
        setResponseData({
            ...responseData,
            [field]: e.target.value,
        });
    };

    const handleUpdate = async () => {
        try {
            const response = await axiosInstance.post(`/chatSettings/chatRules/`, {...responseData}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                toast.success(t("Rules updated successfully."));
            } else {
                toast.error(response.data);
            }
        } catch (error) {
            toast.error(t("Failed to update rules.")+ JSON.stringify(error.response.data));
        }
    };

    return (
        <div className="container mt-4">
            <h1 className="mb-4">{t("Rules")}</h1>
            <div className="card mb-3">
                <div className="card-body">
                    <div>
                        <div className="form-group">
                            <textarea
                                className="form-control"
                                rows="6"
                                value={responseData.rule || ''}
                                onChange={(e) => handleInputChange(e, 'rule')}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <button className="btn btn-primary w-100" onClick={() => handleUpdate()}>
                {t("Update")}
            </button>
        </div>
    );
}

export default DefaultRestriction;
