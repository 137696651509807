import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../../axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const MRP = () => {
    const [mrp, setMrp] = useState([]);
    const [filter, setFilter] = useState("");
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const getMrp = async () => {
            try {
                const response = await axiosInstance.get(`/user/mrp`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                if (response.data !== null) {
                    setMrp(response.data);
                }
            } catch (error) {
                console.error(error);
                toast.error(t("Не вдалося отримати MRP користувача."));
            }
        };
        getMrp();
    }, [navigate, location, t]);

    const handleDeleteMrp = (index) => {
        const deleteMrp = async () => {
            try {
                const response = await axiosInstance.post(`/user/mrp/delete`,
                    { "trigger_word": mrp[index].trigger_word },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                if (response.status === 200) {
                    toast.success(t("MRP було успішно видалено."));
                } else {
                    toast.error(response.data);
                }
            } catch (error) {
                console.error(error);
                toast.error(t("Не вдалося видалити MRP користувача."));
            }
        };
        deleteMrp();
        setMrp(mrp.filter((_, i) => i !== index));
    };

    const filteredMrp = mrp.filter(
        item =>
            item.trigger_word.toLowerCase().includes(filter.toLowerCase()) ||
            item.action.toLowerCase().includes(filter.toLowerCase())
    );

    return (
        <div className="w-100 m-0 d-flex flex-column">
            <div className="bg-secondary p-1 pt-3 pb-3 d-flex align-items-center">
                <input
                    type="text"
                    className="form-control m-1 p-2"
                    placeholder={t("Фільтрувати за тригером або дією...")}
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                />
            </div>
            <div className="p-2 flex-grow-1 overflow-auto">
                {filteredMrp.map((value, index) => (
                    <div key={index} className="bg-secondary p-2 my-1 rounded row m-0 align-items-center">
                        <div className="col-9">
                            <div className="fs-5">
                                {value.trigger_word}
                            </div>
                            <div>
                                {value.action}
                            </div>
                        </div>
                        <div className="col-3 text-danger cursor-pointer" onClick={() => handleDeleteMrp(index)}>
                            {t("Видалити")}
                        </div>
                    </div>
                ))}
            </div>
            <div className="p-2 pb-0">
                <Link to={"edit"} className="btn btn-primary w-100 p-2">
                    {t("Додати")}
                </Link>
            </div>
        </div>
    );
};

export default MRP;
