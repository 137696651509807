import axios from 'axios';

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL || "http://localhost:3000"
});

axiosInstance.interceptors.request.use(
    (config) => {
        try {
            if (window.Telegram && window.Telegram.WebApp) {
                const initData = window.Telegram.WebApp.initData;
                if (initData) {
                    config.headers['X-Init-Data'] = initData;
                }
            }
        } catch (error) {
            console.error('Error setting headers from Telegram WebApp:', error);
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosInstance;
