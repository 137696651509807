import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../axios';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


function AntiSpam() {
    const { chatId } = useParams();
    const [responseData, setResponseData] = useState({});
    const [formData, setFormData] = useState({
        chat_id: '',
        antispam_is_active: false,
        restriction: { id: 2, label: 'Mute' },
        restriction_minutes: 0,
        max_rate_message_count: 0,
        period_in_seconds: 0,
    });
    const { t } = useTranslation();

    useEffect(() => {
        const getAntiSpam = async () => {
            try {
                const response = await axiosInstance.get(`/chatSettings/chatAntispam/${chatId}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });
                setResponseData(response.data);
                setFormData(response.data);
            } catch (error) {
                console.error(error);
                toast.error(t("Failed to fetch antispam settings.")+ JSON.stringify(error.response.data));
            }
        };

        getAntiSpam();
    }, [chatId, t]);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData({
                ...formData,
                [name]: checked,
            });
        } else if (name === 'restriction.id') {
            const label = value === '2' ? 'Mute' : 'Ban';
            setFormData({
                ...formData,
                restriction: {
                    id: parseInt(value),
                    label,
                },
            });
        } else if(type === 'number'){
            setFormData({
                ...formData,
                [name]: parseInt(value),
            });
        }else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosInstance.post('/chatSettings/chatAntispam/', formData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            if (response.status === 200) {
                toast.success(t("Antispam settings updated successfully."));
            } else {
                toast.error(response.data);
            }
        } catch (error) {
            console.error(error);
            toast.error(t("Failed to update antispam settings.")+ JSON.stringify(error.response.data));
        }
    };

    return (
        <div className="container mt-4">
            <h1 className="mb-4">{t("Antispam Settings")}</h1>
            <form onSubmit={handleSubmit}>
                <div className="card mb-3">
                    <div className="card-body">
                        <div className="form-check form-switch mb-3">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                name="antispam_is_active"
                                id="antispamSwitch"
                                checked={formData.antispam_is_active}
                                onChange={handleChange}
                            />
                            <label className="form-check-label" htmlFor="antispamSwitch">{t("Antispam")}</label>
                        </div>
                        <div className="mb-3 form-group">
                            <label htmlFor="max_rate_message_count" className="form-label">{t("Message Count")}:</label>
                            <div className="input-group">
                                <input
                                    type="number"
                                    className="form-control"
                                    id="max_rate_message_count"
                                    name="max_rate_message_count"
                                    value={formData.max_rate_message_count}
                                    onChange={handleChange}
                                />
                                <span className="input-group-text">{t("mes.")}</span>
                            </div>

                        </div>
                        <div className="mb-3">
                            <label htmlFor="period_in_seconds" className="form-label">{t("Period In Seconds")}:</label>
                            <div className="input-group">
                                <input
                                    type="number"
                                    className="form-control"
                                    id="period_in_seconds"
                                    name="period_in_seconds"
                                    value={formData.period_in_seconds}
                                    onChange={handleChange}
                                />
                                <span className="input-group-text">{t("sec.")}</span>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="card mb-3">
                    <div className="card-body">
                        <div className="mb-3">
                            <label htmlFor="restriction" className="form-label">{t("Restriction")}:</label>
                            <select
                                className="form-select"
                                id="restriction"
                                name="restriction.id"
                                value={formData.restriction.id}
                                onChange={handleChange}
                            >
                                <option value="2">{t("Mute")}</option>
                                <option value="3">{t("Ban")}</option>
                            </select>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="restriction_minutes" className="form-label">{t("Restriction Minutes")}:</label>
                            <div className="input-group">
                                <input
                                    type="number"
                                    className="form-control"
                                    id="restriction_minutes"
                                    name="restriction_minutes"
                                    value={formData.restriction_minutes}
                                    onChange={handleChange}
                                />
                                <span className="input-group-text">{t("min.")}</span>
                            </div>

                        </div>
                    </div>
                </div>

                <button type="submit" className="btn btn-primary w-100">{t("Update")}</button>
            </form>
        </div>
    );
}

export default AntiSpam;
