import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from "../../../axios";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const MrpForm = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { trigger_word = '', action = '' } = location.state || {};
    const { t } = useTranslation();

    const [currentMrp, setCurrentMrp] = useState({ trigger_word, action });
    const [showExample, setShowExample] = useState(false);

    const handleSave = () => {
        const saveMrp = async () => {
            try {
                const response = await axiosInstance.post(`/user/mrp/save`,
                    currentMrp,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    });
                if (response.status === 200) {
                    toast.success(t("Mrp was saved successfully."));
                } else {
                    toast.error(response.data);
                }
            } catch (error) {
                console.error(error);
                toast.error(t("Failed to save mrp."));
            }
        }
        saveMrp()
        navigate(-1)
    };

    return (
        <div className="container mt-4">
            <h1 className="mb-4">{t("New MRP")}</h1>
            <div className="card mb-3">
                <div className="card-body">
                    <div className="form-group mb-3">
                        <label htmlFor="trigger">{t("Trigger Word")}</label>
                        <input
                            type="text"
                            className="form-control"
                            id="trigger_word"
                            value={currentMrp.trigger_word}
                            onChange={(e) => setCurrentMrp({ ...currentMrp, trigger_word: e.target.value })}
                        />
                    </div>
                    <div className="form-group mt-3">
                        <label htmlFor="action">{t("Action")}</label>
                        <textarea
                            className="form-control"
                            id="action"
                            rows="3"
                            value={currentMrp.action}
                            onChange={(e) => setCurrentMrp({ ...currentMrp, action: e.target.value })}
                        />
                    </div>
                    <button type="button" className="btn btn-primary w-100 mt-4" onClick={handleSave}>
                        {t("Save")}
                    </button>
                </div>
            </div>

            <div id="accordion">
                <div className="card">
                    <div className="card-header" id="heading1">
                        <h5 className="mb-0">
                            <button className="btn btn-link" data-toggle="collapse" data-target="#collapse1" aria-expanded="true" aria-controls="collapse1">
                                {t("What is MRP?")}
                            </button>
                        </h5>
                    </div>

                    <div id="collapse1" className="collapse" aria-labelledby="heading1" data-parent="#accordion">
                        <div className="card-body">
                            {t("MRP is a personalized command (My Role Play) for chats, helps to create more different fun situations and have more fun, not limiting yourself to a list of standard commands (rp), but only to your imagination, using the trigger word to display text with an action.")}
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header" id="heading2">
                        <h5 className="mb-0">
                            <button className="btn btn-link" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                                {t("How to create MRP?")}
                            </button>
                        </h5>
                    </div>

                    <div id="collapse2" className="collapse" aria-labelledby="heading2" data-parent="#accordion">
                        <div className="card-body">
                            {t("To create your own MRP, you need to specify a trigger word and an action text.")}<br/><br/>
                            {t("The text with action includes 3 elements, a pointer to you, a pointer to the target, and a pointer to the text that can be included in the message with the trigger word.")}<br/>
                            {t("Pointers:")}<br/>
                            {t("@1 - To you")}<br/>
                            {t("@2 - To the target")}<br/>
                            {t("@t - To the text after the trigger word (that is, the text that you write differently each time, as a clarification after using the command)")}
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header" id="heading3">
                        <h5 className="mb-0">
                            <button className="btn btn-link" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                                {t("Example of creation")}
                            </button>
                        </h5>
                    </div>

                    <div id="collapse3" className="collapse" aria-labelledby="heading3" data-parent="#accordion">
                        <div className="card-body">
                            {t("Trigger word: thank you")}<br/>
                            {t("Action: @1 thanked @2 @t")}<br/><br/>
                            {t("Example of use:")}<br/>
                            <blockquote>
                                {t("Roman (replying to Jamal):")}<br/>
                                {t("thank you for your help")}<br/>
                            </blockquote>
                            <blockquote>
                                {t("Bot")}:<br/>
                                {t("Roman thanked Jamal for your help")}
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MrpForm;
