import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function Root() {
    const navigate = useNavigate();
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const [startLocation, setStartLocation] = useState("");  // To store the initial location

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    useEffect(() => {
        // Initialize Telegram Web App
        const tg = window.Telegram.WebApp;
        tg.ready();
        tg.expand(true);

        const themeParams = tg.themeParams;

        // Set theme params to CSS variables
        Object.keys(themeParams).forEach(param => {
            document.documentElement.style.setProperty(`--tg-theme-${param}`, themeParams[param]);
        });

        tg.setHeaderColor(tg.themeParams.secondary_bg_color);
        tg.setBackgroundColor(tg.themeParams.secondary_bg_color);

        // Handle theme changes dynamically
        tg.onEvent('themeChanged', () => {
            const updatedThemeParams = window.Telegram.WebApp.themeParams;
            Object.keys(updatedThemeParams).forEach(param => {
                document.documentElement.style.setProperty(`--tg-theme-${param}`, updatedThemeParams[param]);
            });
        });

        // Set start location only once when the component is mounted
        if (!startLocation) {
            setStartLocation(location.pathname);
        }

    }, [startLocation]);

    useEffect(() => {
        const tg = window.Telegram.WebApp;
        // Show or hide the back button based on current path
        if (startLocation === location.pathname) {
            tg.BackButton.hide();
        } else {
            tg.BackButton.show();
            tg.BackButton.onClick(() => navigate(-1));
        }
    }, [location.pathname, startLocation, navigate]);

    return (
        <div>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="dark"
            />
            <Outlet />
        </div>
    );
}

export default Root;
