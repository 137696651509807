import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../../axios';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


function GreetingsSettings() {
    const { chatId } = useParams();
    const [responseData, setResponseData] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        const getGreetingsSettings = async () => {
            try {
                const response = await axiosInstance.get(`/chatSettings/chatGreetings/${chatId}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                setResponseData(response.data);
            } catch (error) {
                console.log(error);
                toast.error(t("Failed to fetch greeting settings.")+ JSON.stringify(error.response.data));
            }
        };

        getGreetingsSettings();
    }, [chatId, t]);

    const handleInputChange = (e, field) => {
        setResponseData({
            ...responseData,
            [field]: e.target.value,
        });
    };

    const handleCheckboxChange = (e, field) => {
        setResponseData({
            ...responseData,
            [field]: e.target.checked,
        });
    };

    const handleUpdate = async () => {
        try {
            const response = await axiosInstance.post(`/chatSettings/chatGreetings/`, {...responseData}, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            if (response.status === 200) {
                toast.success(t("Greeting settings updated successfully."));
            } else {
                toast.error(response.data);
            }
        } catch (error) {
            toast.error(t("Failed to update greeting settings.")+ JSON.stringify(error.response.data));
        }
    };

    return (
        <div className="container mt-4">
            <h1 className="mb-4">{t("Greetings Settings")}</h1>

            <div className="card mb-3">
                <div className="card-body">
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="greetingsToggle"
                            checked={responseData.greeting_on || false}
                            onChange={(e) => handleCheckboxChange(e, 'greeting_on')}
                        />
                        <label className="form-check-label" htmlFor="greetingsToggle">
                            {t("Greetings text")}
                        </label>
                    </div>
                    <div className="mt-3">
                        <blockquote>{t("Use @ as tag of new user")}</blockquote>
                        <textarea
                            className="form-control mt-2"
                            rows="3"
                            value={responseData.greeting_text || ''}
                            onChange={(e) => handleInputChange(e, 'greeting_text')}
                        />
                    </div>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body">
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="goodbyeToggle"
                            checked={responseData.goodbye_on || false}
                            onChange={(e) => handleCheckboxChange(e, 'goodbye_on')}
                        />
                        <label className="form-check-label" htmlFor="goodbyeToggle">
                            {t("Goodbye text")}
                        </label>
                    </div>
                    <div className="mt-3">
                        <blockquote>{t("Use @ as tag of new user")}</blockquote>
                        <textarea
                            className="form-control mt-2"
                            rows="3"
                            value={responseData.goodbye_text || ''}
                            onChange={(e) => handleInputChange(e, 'goodbye_text')}
                        />
                    </div>
                </div>
            </div>

            <button className="btn btn-primary w-100" onClick={handleUpdate}>
                {t("Update")}
            </button>
        </div>
    );
}

export default GreetingsSettings;
